<template>
  <div>
    <form v-if="form" class="mt-5" @submit.prevent="submit">
      <b-collapse class="panel" animation="slide">
        <template #trigger="props">
          <div class="panel-heading">
            <h2>{{ $t("general") }}</h2>
            <b-icon :icon="props.open ? 'chevron-up' : 'chevron-down'"></b-icon>
          </div>
        </template>
        <div class="panel-block">
          <CompanyFormGeneral
            :form="form"
            :mode="mode"
            :companies="companies"
            :currencies="currencies"
            :system-of-measurements="systemOfMeasurements"
          ></CompanyFormGeneral>
        </div>
      </b-collapse>

      <div v-if="mode === 'create'" class="mt-5">
        <b-collapse class="panel" animation="slide">
          <template #trigger="props">
            <div class="panel-heading">
              <h2>{{ $t("address") }}</h2>
              <b-icon
                :icon="props.open ? 'chevron-up' : 'chevron-down'"
              ></b-icon>
            </div>
          </template>
          <div class="panel-block">
            <CompanyFormAddress :address="form.address"></CompanyFormAddress>
          </div>
        </b-collapse>
      </div>

      <div v-if="mode === 'create'" class="mt-5">
        <b-collapse class="panel" animation="slide">
          <template #trigger="props">
            <div class="panel-heading">
              <h2>{{ $t("shipping_address") }}</h2>
              <b-icon
                :icon="props.open ? 'chevron-up' : 'chevron-down'"
              ></b-icon>
            </div>
          </template>
          <div class="panel-block">
            <b-field horizontal>
              <b-checkbox v-model="shippingAddressMimicsAddress">
                {{ $t("same_as_address") }}
              </b-checkbox>
            </b-field>

            <CompanyFormAddress
              :address="form.shippingAddress"
              :disabled="shippingAddressMimicsAddress"
            ></CompanyFormAddress>
          </div>
        </b-collapse>
      </div>

      <div v-if="mode === 'create'" class="mt-5">
        <b-collapse class="panel" animation="slide">
          <template #trigger="props">
            <div class="panel-heading">
              <h2>{{ $t("billing_information") }}</h2>
              <b-icon
                :icon="props.open ? 'chevron-up' : 'chevron-down'"
              ></b-icon>
            </div>
          </template>
          <div class="panel-block">
            <CompanyFormBillingInformation
              :billing-information="form.billingInformation"
              @mimics="setBillingInformationMimicsAddress"
            ></CompanyFormBillingInformation>
          </div>
        </b-collapse>
      </div>

      <div v-if="mode === 'create'" class="mt-5">
        <b-collapse class="panel" animation="slide">
          <template #trigger="props">
            <div class="panel-heading">
              <h2>{{ $t("commercial_contact") }}</h2>
              <b-icon
                :icon="props.open ? 'chevron-up' : 'chevron-down'"
              ></b-icon>
            </div>
          </template>
          <div class="panel-block">
            <b-field horizontal>
              <b-checkbox v-model="commercialContactMimicsBillingInformation">
                {{ $t("same_as_billing_information") }}
              </b-checkbox>
            </b-field>

            <CompanyFormContact
              :contact="form.commercialContact"
              :disabled="commercialContactMimicsBillingInformation"
            ></CompanyFormContact>
          </div>
        </b-collapse>
      </div>

      <div v-if="mode === 'create'" class="mt-5">
        <b-collapse class="panel" animation="slide">
          <template #trigger="props">
            <div class="panel-heading">
              <h2>{{ $t("operational_contact") }}</h2>
              <b-icon
                :icon="props.open ? 'chevron-up' : 'chevron-down'"
              ></b-icon>
            </div>
          </template>
          <div class="panel-block">
            <b-field horizontal>
              <b-checkbox v-model="operationalContactMimicsBillingInformation">
                {{ $t("same_as_billing_information") }}
              </b-checkbox>
            </b-field>

            <CompanyFormContact
              :contact="form.operationalContact"
              :disabled="operationalContactMimicsBillingInformation"
            ></CompanyFormContact>
          </div>
        </b-collapse>
      </div>

      <div v-if="mode === 'create'" class="mt-5">
        <b-collapse class="panel" animation="slide">
          <template #trigger="props">
            <div class="panel-heading">
              <h2>{{ $t("admin") }}</h2>
              <b-icon
                :icon="props.open ? 'chevron-up' : 'chevron-down'"
              ></b-icon>
            </div>
          </template>
          <div class="panel-block">
            <UserAutocomplete
              @selected="
                (e) => (form.users[0].userAccount = e ? e['@id'] : null)
              "
            ></UserAutocomplete>
          </div>
        </b-collapse>
      </div>

      <b-button
        type="is-primary"
        native-type="submit"
        icon-left="content-save"
        :class="{ 'mt-5': mode === 'create' }"
        :loading="loading"
        :disabled="!valid"
      >
        {{ $t("save") }}
      </b-button>
    </form>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import UserAutocomplete from "../../../components/forms/UserAutocomplete";
import CompanyFormAddress from "./CompanyFormAddress";
import CompanyFormBillingInformation from "./CompanyFormBillingInformation";
import CompanyFormContact from "./CompanyFormContact";
import CompanyFormGeneral from "./CompanyFormGeneral";
import DefaultCompany from "./DefaultCompany";

export default {
  components: {
    CompanyFormAddress,
    CompanyFormBillingInformation,
    CompanyFormContact,
    CompanyFormGeneral,
    UserAutocomplete,
  },
  props: {
    mode: {
      type: String,
      required: true,
    },
    company: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      form: null,
      companies: [],
      shippingAddressMimicsAddress: false,
      billingInformationMimicsAddress: false,
      commercialContactMimicsBillingInformation: false,
      operationalContactMimicsBillingInformation: false,
    };
  },
  computed: {
    ...mapState({
      currencies: (state) => state.global.currencies,
      systemOfMeasurements: (state) => state.global.systemOfMeasurements,
    }),
    valid() {
      if (this.mode === "edit") {
        return true;
      }

      return this.form.users.length > 0 && this.form.users[0].userAccount;
    },
  },
  watch: {
    shippingAddressMimicsAddress(value) {
      if (!value) {
        return;
      }
      this.form.shippingAddress = { ...this.form.address };
    },
    billingInformationMimicsAddress(value) {
      if (!value) {
        return;
      }
      this.form.billingInformation.address = { ...this.form.address };
    },
    commercialContactMimicsBillingInformation(value) {
      if (!value) {
        return;
      }
      this.form.commercialContact = this.form.billingInformation.contact;
    },
    operationalContactMimicsBillingInformation(value) {
      if (!value) {
        return;
      }
      this.form.operationalContact = this.form.billingInformation.contact;
    },
    "form.address": {
      deep: true,
      handler(value) {
        if (this.shippingAddressMimicsAddress) {
          this.form.shippingAddress = { ...value };
        }
        if (this.billingInformationMimicsAddress) {
          this.form.billingInformation.address = { ...value };
        }
      },
    },
    "form.billingInformation.contact": {
      deep: true,
      handler(value) {
        if (this.commercialContactMimicsBillingInformation) {
          this.form.commercialContact = { ...value };
        }
        if (this.operationalContactMimicsBillingInformation) {
          this.form.operationalContact = { ...value };
        }
      },
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions(["getAllCompanies"]),
    init() {
      this.getAllCompanies().then((companies) => {
        this.companies = companies;
      });

      if (this.mode === "create") {
        this.form = {
          ...DefaultCompany,
          systemOfMeasurement: this.systemOfMeasurements[0]["@id"],
          currency: this.currencies[0]["@id"],
          users: [
            {
              userAccount: null,
              roles: ["ROLE_COMPANY_ADMIN"],
            },
          ],
        };
      }

      if (this.mode === "edit") {
        this.form = {
          name: this.company.name,
          vatNumber: this.company.vatNumber,
          emailAddress: this.company.emailAddress,
          phoneNumber: this.company.phoneNumber,
          website: this.company.website,
          systemOfMeasurement: this.company.systemOfMeasurement["@id"],
          currency: this.company.currency["@id"],
          region: this.company.region,
          supportAndAnalysisConsent: this.company.supportAndAnalysisConsent,
        };
      }
    },
    submit() {
      this.$emit("submit", {
        ...this.form,
      });
    },
    setBillingInformationMimicsAddress(value) {
      this.billingInformationMimicsAddress = value;
    },
  },
};
</script>
