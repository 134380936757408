<template>
  <div>
    <b-field horizontal :label="$t('region')" label-for="region">
      <b-select
        id="region"
        v-model="form.region"
        required
        :disabled="mode === 'edit'"
      >
        <option v-for="r in regions" :key="r.code" :value="r.code">
          {{ r.name }}
        </option>
      </b-select>
    </b-field>

    <CustomInput
      v-model="form.name"
      :label="$t('name')"
      :invalid="nameInvalid"
      :invalid-message="nameInvalidMessage"
      @input="checkUniqueName"
    ></CustomInput>

    <CustomInput
      v-model="form.vatNumber"
      :label="$t('vat_number')"
    ></CustomInput>

    <CustomInput
      v-model.trim="form.emailAddress"
      :label="$t('email')"
      type="email"
      :required="false"
    ></CustomInput>

    <CustomInput
      v-model="form.phoneNumber"
      :label="$t('phone')"
      :required="false"
    ></CustomInput>

    <CustomInput
      v-model="form.website"
      :label="$t('website')"
      :required="false"
    ></CustomInput>

    <b-field horizontal :label="$tc('currency', 1)" label-for="currency">
      <b-select id="currency" v-model="form.currency" required>
        <option v-for="c in currencies" :key="c.id" :value="c['@id']">
          {{ c.name }}
        </option>
      </b-select>
    </b-field>

    <b-field
      horizontal
      :label="$tc('system_of_measurement', 1)"
      label-for="systemOfMeasurement"
    >
      <b-select
        id="systemOfMeasurement"
        v-model="form.systemOfMeasurement"
        required
      >
        <option v-for="s in systemOfMeasurements" :key="s.id" :value="s['@id']">
          {{ s.name }}
        </option>
      </b-select>
    </b-field>

    <b-field horizontal>
      <b-checkbox v-model="form.supportAndAnalysisConsent">
        {{ $t("consent") }}
      </b-checkbox>
    </b-field>
  </div>
</template>

<script>
import CustomInput from "../../../components/forms/CustomInput";
import Regions from "../../../helpers/Regions";

export default {
  components: { CustomInput },
  props: {
    form: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      required: true,
    },
    companies: {
      type: Array,
      required: true,
    },
    currencies: {
      type: Array,
      required: true,
    },
    systemOfMeasurements: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      regions: Regions.all,
      nameInvalid: false,
      nameInvalidMessage: null,
    };
  },
  methods: {
    checkUniqueName(name) {
      if (!name || this.mode === "edit") {
        this.nameInvalid = false;
        this.nameInvalidMessage = null;
        return;
      }

      const slugA = name.replace(/[^a-z0-9]/gi, "").toLowerCase();
      const match = this.companies.find((c) => {
        const slugB = c.name.replace(/[^a-z0-9]/gi, "").toLowerCase();
        return slugA === slugB;
      });

      this.nameInvalid = !!match;
      this.nameInvalidMessage = match ? `${match.name} already exists.` : null;
    },
  },
};
</script>
