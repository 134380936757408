export default {
  region: "eu",
  name: null,
  vatNumber: null,
  emailAddress: null,
  phoneNumber: null,
  website: null,
  systemOfMeasurement: null,
  currency: null,
  supportAndAnalysisConsent: true,
  address: {
    address: null,
    postcode: null,
    city: null,
    state: null,
    country: "BE",
  },
  shippingAddress: {
    address: null,
    postcode: null,
    city: null,
    state: null,
    country: "BE",
  },
  billingInformation: {
    emailAddress: null,
    contact: {
      name: null,
      emailAddress: null,
      phoneNumber: null,
    },
    address: {
      address: null,
      postcode: null,
      city: null,
      state: null,
      country: "BE",
    },
  },
  commercialContact: {
    name: null,
    emailAddress: null,
    phoneNumber: null,
  },
  operationalContact: {
    name: null,
    emailAddress: null,
    phoneNumber: null,
  },
  users: [
    {
      userAccount: null,
      roles: ["ROLE_COMPANY_ADMIN"],
    },
  ],
};
